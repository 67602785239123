import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ProgressBar = ({ value, label }) => {
  const getColor = (value) => {
    if (value <= 25) return 'error';
    if (value > 25 && value <= 50) return 'warning';
    if (value > 50 && value <= 75) return 'info';
    return 'success';
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        {label}: {value}%
      </Typography>
      <LinearProgress
        variant="determinate"
        value={value}
        color={getColor(value)}
        sx={{ height: 10, borderRadius: 5 }}
      />
    </Box>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default ProgressBar;
