import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css'; // Ensure your CSS is linked properly
import App from './App'; // The main component with routing
import { BrowserRouter } from 'react-router-dom'; // BrowserRouter for routing

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App /> {/* Ensure App is wrapped in BrowserRouter for routing */}
    </BrowserRouter>
  </React.StrictMode>
);
