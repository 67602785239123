import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Divider } from '@mui/material';
import { Inbox as InboxIcon, Mail as MailIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const DashboardSidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        {[
          { text: 'Main List', path: '/' },
          { text: 'Sender', path: '/sender' },
          { text: 'Content', path: '/content' },
          { text: 'Links', path: '/links' },
          { text: 'Authentication', path: '/authentication' },
          { text: 'Attachments', path: '/attachments' },
          { text: 'Total Evaluation', path: '/totalevaluation' },
        ].map((item, index) => (
          <ListItem button key={item.text} component={Link} to={item.path}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default DashboardSidebar;
