import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import ProgressBar from './ProgressBar';
import PropTypes from 'prop-types';

const TotalEvaluation = ({ email }) => {
  if (!email) {
    return <Typography>No email selected for evaluation.</Typography>;
  }

  const performEvaluation = () => {
    let score = 0;
    if (email.mailHeaders?.subject) score += 25;
    if (email.mailHeaders?.from) score += 25;
    if (email.emailBody?.length > 50) score += 25;
    return score;
  };

  const evaluationScore = performEvaluation();

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h5" component="h3">
              Total Evaluation for Email
            </Typography>
            <Typography variant="subtitle1">Subject: {email.mailHeaders?.subject}</Typography>
            <Typography variant="subtitle1">From: {email.mailHeaders?.from}</Typography>
            <Typography variant="body2">Date: {email.mailHeaders?.date}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Evaluation Score</Typography>
            <ProgressBar value={evaluationScore} label="Evaluation Score" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

TotalEvaluation.propTypes = {
  email: PropTypes.object, // Allow null/undefined email
};

export default TotalEvaluation;
