import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import PropTypes from 'prop-types';

const SearchComponent = ({ onEmailSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://reporting.whatthephish.ai/emails?query=${searchQuery}`);
      const data = await response.json();
      setSearchResults(data); // Assuming the API returns an array of email objects
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Search for an Email
      </Typography>
      <TextField
        label="Search by email, sender, domain, or classification"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        disabled={isLoading}
      >
        {isLoading ? 'Searching...' : 'Search'}
      </Button>

      {searchResults.length > 0 && (
        <List sx={{ mt: 2 }}>
          {searchResults.map((email) => (
            <ListItem key={email.id}>
            <ListItemButton onClick={() => onEmailSelect(email)}>
              <ListItemText 
                primary={email.mailHeaders.subject || `Email ${email.id}`} 
                secondary={`From: ${email.senderEmail}, Classification: ${email.classification}`} 
              />
            </ListItemButton>
          </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
};

SearchComponent.propTypes = {
  onEmailSelect: PropTypes.func.isRequired, // Function to handle email selection
};

export default SearchComponent;
