import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const Sender = ({ senderData }) => {
  const [senderInfo, setSenderInfo] = useState({
    emailAdr: '',
    displayName: '',
    senderScore: 0,
    nameSimilarity: '',
    ipAdr: '',
    ipURL: '',
  });

  useEffect(() => {
    if (senderData) {
      const { emailAddress, displayName, senderScore, ipAddress, ipURL } = senderData;
      setSenderInfo({
        emailAdr: emailAddress || 'N/A',
        displayName: displayName || 'N/A',
        senderScore: senderScore || 0,
        nameSimilarity: 'High Similarity', // Assuming some similarity check logic
        ipAdr: ipAddress || 'N/A',
        ipURL: ipURL || '',
      });
    }
  }, [senderData]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Sender Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Display Name:</strong> {senderInfo.displayName}
            </Typography>
            <Typography variant="body1">
              <strong>Email Address:</strong> {senderInfo.emailAdr}
            </Typography>
            <Typography variant="body1">
              <strong>Similarity:</strong> {senderInfo.nameSimilarity}
            </Typography>
            <ProgressBar value={senderInfo.senderScore} label="Sender Score" />
          </Paper>
        </Grid>
        {senderInfo.ipURL && (
          <Grid item xs={12}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="body1">
                <strong>Senders IP Address:</strong> {senderInfo.ipAdr}
              </Typography>
              <a href={senderInfo.ipURL} target="_blank" rel="noopener noreferrer">
                Check IP
              </a>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Sender.propTypes = {
  senderData: PropTypes.object, // Allow null/undefined sender data
};

export default Sender;
