import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const Attachments = ({ attachmentData }) => {
  const [attachmentInfo, setAttachmentInfo] = useState({
    nameOfFilesArray: [],
    numFiles: 0,
    attachmentScore: 0,
    totVal: 0,
  });

  useEffect(() => {
    if (attachmentData) {
      const { files = [], attachmentScore = 0 } = attachmentData;
      setAttachmentInfo({
        nameOfFilesArray: files.map((file) => file.name),
        numFiles: files.length,
        attachmentScore,
        totVal: attachmentScore,
      });
    }
  }, [attachmentData]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Email Attachments Analysis</Typography>
      <Grid container spacing={3}>
        {/* Attachment List */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Number of Files Attached:</strong> {attachmentInfo.numFiles}
            </Typography>
            <Typography variant="body1">
              <strong>File Names:</strong>
            </Typography>
            <ul>
              {attachmentInfo.nameOfFilesArray.map((fileName, index) => (
                <li key={index}>{fileName}</li>
              ))}
            </ul>
            <ProgressBar value={attachmentInfo.attachmentScore} label="Attachment Safety Score" />
          </Paper>
        </Grid>

        {/* Total Evaluation */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Total Attachment Evaluation:</strong> {attachmentInfo.totVal}%
            </Typography>
            <ProgressBar value={attachmentInfo.totVal} label="Total Attachment Score" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

Attachments.propTypes = {
  attachmentData: PropTypes.object, // Allow null/undefined data
};

export default Attachments;
