import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const Authentication = ({ authData, onAuthScore }) => {
  const [authInfo, setAuthInfo] = useState({
    spfScore: 0,
    dkimScore: 0,
    dmarcScore: 0,
    totVal: 0,
    spfText: '',
    dkimText: '',
    dmarcText: '',
  });

  useEffect(() => {
    if (authData) {
      const { spfScore, dkimScore, dmarcScore, spfText, dkimText, dmarcText, totalScore } = authData;
      setAuthInfo({
        spfScore,
        dkimScore,
        dmarcScore,
        totVal: totalScore,
        spfText,
        dkimText,
        dmarcText,
      });
      onAuthScore(totalScore);
    }
  }, [authData, onAuthScore]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Email Authentication</Typography>
      <Grid container spacing={3}>
        {/* SPF Section */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>SPF Result:</strong> {authInfo.spfText}
            </Typography>
            <ProgressBar value={authInfo.spfScore} label="SPF Score" />
          </Paper>
        </Grid>

        {/* DKIM Section */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>DKIM Result:</strong> {authInfo.dkimText}
            </Typography>
            <ProgressBar value={authInfo.dkimScore} label="DKIM Score" />
          </Paper>
        </Grid>

        {/* DMARC Section */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>DMARC Result:</strong> {authInfo.dmarcText}
            </Typography>
            <ProgressBar value={authInfo.dmarcScore} label="DMARC Score" />
          </Paper>
        </Grid>

        {/* Total Evaluation */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Total Authentication Evaluation:</strong> {authInfo.totVal}%
            </Typography>
            <ProgressBar value={authInfo.totVal} label="Total Authentication Score" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

Authentication.propTypes = {
  authData: PropTypes.object.isRequired,
  onAuthScore: PropTypes.func.isRequired,
};

export default Authentication;
