import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const Content = ({ contentData }) => {
  const [contentInfo, setContentInfo] = useState({
    receiverScore: 0,
    dictScore: 0,
    spamWordCount: 0,
    list: [],
    totVal: 0,
  });

  useEffect(() => {
    if (contentData) {
      const { receiverScore, dictScore, spamWords, totalScore } = contentData;
      setContentInfo({
        receiverScore: receiverScore || 0,
        dictScore: dictScore || 0,
        spamWordCount: spamWords ? spamWords.length : 0,
        list: spamWords || [],
        totVal: totalScore || 0,
      });
    }
  }, [contentData]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Message Content</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Receivers Name Mentioned:</strong> {contentInfo.receiverScore}%
            </Typography>
            <ProgressBar value={contentInfo.receiverScore} label="Receiver Score" />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Phishing Words Found:</strong> {contentInfo.spamWordCount}
            </Typography>
            <ul>
              {contentInfo.list.map((word, index) => (
                <li key={index}>{word}</li>
              ))}
            </ul>
            <ProgressBar value={contentInfo.dictScore} label="Phishing Word Score" />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Total Content Evaluation:</strong> {contentInfo.totVal}%
            </Typography>
            <ProgressBar value={contentInfo.totVal} label="Total Content Score" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

Content.propTypes = {
  contentData: PropTypes.object, // Allow null/undefined content data
};

export default Content;
