import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, Box, Container, Toolbar } from '@mui/material';
//import MainList from './MainList';
import Sender from './Sender';
import Content from './Content';
import Links from './Links';
import Authentication from './Authentication';
import Attachments from './Attachments';
import TotalEvaluation from './TotalEvaluation';
import DashboardSidebar from './DashboardSidebar';
import DashboardHeader from './DashboardHeader';
import SearchComponent from './SearchComponent'; // Import the search component

const App = () => {
  const [selectedEmail, setSelectedEmail] = useState(null);

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <DashboardHeader />
        <DashboardSidebar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Routes>
              {/* Menu page for email selection via search */}
              <Route 
                path="/" 
                element={<SearchComponent onEmailSelect={setSelectedEmail} />} 
              />

              {/* Total Evaluation page */}
              <Route 
                path="/totalevaluation" 
                element={<TotalEvaluation email={selectedEmail} />} 
              />

              {/* Other component routes */}
              <Route 
                path="/sender" 
                element={<Sender senderData={selectedEmail?.senderEmail} />} 
              />
              <Route 
                path="/content" 
                element={<Content contentData={selectedEmail?.emailBody} />} 
              />
              <Route 
                path="/links" 
                element={<Links linkData={selectedEmail?.details?.links} />} 
              />
              <Route 
                path="/authentication" 
                element={<Authentication authData={selectedEmail?.details?.authentication} />} 
              />
              <Route 
                path="/attachments" 
                element={<Attachments attachmentData={selectedEmail?.details?.attachments} />} 
              />
            </Routes>
          </Container>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default App;
