import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const Links = ({ linkData }) => {
  const [linkInfo, setLinkInfo] = useState({
    linkCount: 0,
    suspiciousLinkCount: 0,
    linkScore: 0,
    list: [],
    totVal: 0,
  });

  useEffect(() => {
    if (linkData) {
      const { totalLinks = [], suspiciousLinks = [], linkScore = 0 } = linkData;
      setLinkInfo({
        linkCount: totalLinks.length,
        suspiciousLinkCount: suspiciousLinks.length,
        list: totalLinks,
        linkScore,
        totVal: linkScore,
      });
    }
  }, [linkData]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Email Links Analysis</Typography>
      <Grid container spacing={3}>
        {/* Total Links */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Total Links Found:</strong> {linkInfo.linkCount}
            </Typography>
            <ul>
              {linkInfo.list.map((link, index) => (
                <li key={index}>{link}</li>
              ))}
            </ul>
            <ProgressBar value={linkInfo.linkScore} label="Link Safety Score" />
          </Paper>
        </Grid>

        {/* Suspicious Links */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Suspicious Links Found:</strong> {linkInfo.suspiciousLinkCount}
            </Typography>
            <Typography variant="body1">
              These links contain suspicious patterns that may indicate phishing attempts.
            </Typography>
            <ProgressBar value={linkInfo.linkScore} label="Suspicious Link Score" />
          </Paper>
        </Grid>

        {/* Total Evaluation */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Total Link Evaluation:</strong> {linkInfo.totVal}%
            </Typography>
            <ProgressBar value={linkInfo.totVal} label="Total Link Score" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

Links.propTypes = {
  linkData: PropTypes.object, // Allow null/undefined data
};

export default Links;
